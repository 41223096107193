import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import Schools from 'src/components/schools/schools';
import SEO from 'src/components/seo';

const SchoolsPage = () => {
  return (
    <Layout>
      <SEO title="Schools" />
      <PrivateRoute>
        <Schools />
      </PrivateRoute>
    </Layout>
  );
};

export default SchoolsPage;
